import {graphql, withPrefix} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import {Button} from '../../../components/button';
import {CaseHeader} from '../../../components/case_header';
import {WillAnimate} from '../../../components/content_animate';
import {Layout} from '../../../components/layout';
import {ScrollTo} from '../../../components/scroll_to';

const translatedPagePath = '/en/portfolio/valuation-platform-taxapi/';

export default ({data}: any) => (
    <Layout
        title="Case: Taxatieplatform TaXapi – Modern vastgoed taxeren – Voorbeeld van ons werk"
        description="Samen met TaXapi ontwikkelen we een innovatieve progressive webapplicatie waarmee al het werk van de taxateur leuk en gemakkelijk uit te voeren is."
        ogImage="taxapi.png"
        translatedPagePath={translatedPagePath}
        navColor="white">
        <CaseHeader
            urlBack="/nl/portfolio/vertaalplatform-fairlingo/"
            titleBack="Case: Vertaalplatform Fairlingo"
            urlForward="/nl/portfolio/saas-product-keeping/"
            titleForward="Case: Ons eigen product: Keeping"
            translatedPagePath={translatedPagePath}>
            <Img className="hero__image" alt="RP3" fluid={data.taxapi.childImageSharp.fluid} critical />
            <div className="hero__content-wrapper">
                <div className="hero__content">
                    <h1>
                        {/* Make sure to have the correct spacing when br becomes invisible */}
                        Modern vastgoed <br /> taxeren
                    </h1>
                    <h2>Taxatieplatform TaXapi</h2>
                </div>
            </div>
            <ScrollTo className="hero__scroll" target="/nl/portfolio/taxatieplatform-taxapi/#from-hero" />
        </CaseHeader>

        <section className="section">
            {/* Case intro */}
            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display content__display--intro">
                        <h2>
                            TaXapi timmert dankzij de inno&shy;vatieve web&shy;applicatie aan de weg naar een nieuw,{' '}
                            <span className="highlight">sneller</span> en beter{' '}
                            <span className="highlight">taxatie&shy;proces</span>, voor alle taxateurs en klanten.
                        </h2>

                        <ul className="list" style={{marginTop: '3rem'}}>
                            <li className="ion-md-medal gray">
                                Computable Awards: genomineerd binnen de categorie{' '}
                                <a
                                    title="Computable Awards: nominatie Taxatieplatform TaXapi"
                                    href="https://www.computable.nl/artikel/informatie/awards-nieuws/6669955/1853296/taxapi-transformeert-taxatieproces-vastgoed.html"
                                    target="_blank"
                                    rel="noreferrer">
                                    beste ICT&ndash;project MKB
                                </a>
                                .
                            </li>
                            <li className="ion-md-medal gray">
                                Dutch Interactive Awards: genomineerd binnen de categorie{' '}
                                <a
                                    title="Dutch Interactive Awards: nominatie Taxatieplatform TaXapi"
                                    href="http://www.dutchinteractiveawards.nl/nominatie/modern-vastgoed-taxeren-taxatieplatform-taxapi-label305/"
                                    target="_blank"
                                    rel="noreferrer">
                                    service
                                </a>
                                .
                            </li>
                        </ul>
                    </div>
                </div>
            </WillAnimate>

            {/* Case assignment */}
            <WillAnimate as="article" id="from-hero" className="content">
                <div className="container">
                    <div className="content__display content__display--right">
                        <h2>Opdracht</h2>
                    </div>

                    <div className="content__copy">
                        <p className="p-lead">
                            De medeoprichters van TaXapi zijn jarenlang actief geweest als taxateurs, zij kenden als{' '}
                            geen ander de haken en ogen van het taxatieproces.
                        </p>
                        <p>
                            TaXapi vroeg ons om dit <span className="highlight">innovatieve</span> idee om te zetten{' '}
                            naar een online platform waar taxateurs en klanten beide eenvoudig mee overweg kunnen.
                        </p>
                        <p>
                            We gingen samen de uitdaging aan om het taxatieproces opnieuw te definiëren. TaXapi wilde{' '}
                            ervoor zorgen dat taxateurs <span className="highlight">meer tijd</span> kunnen besteden in{' '}
                            het huis dat ze taxeren, en minder tijd hoeven te besteden aan het bijkomende papier- en{' '}
                            kantoorwerk.
                        </p>

                        <ul className="list">
                            <li className="ion-md-arrow-dropright">Productontwerp en -advies</li>
                            <li className="ion-md-arrow-dropright">UX- en UI-ontwerp</li>
                            <li className="ion-md-arrow-dropright">Webontwikkeling (PWA)</li>
                            <li className="ion-md-arrow-dropright">Integraties met externe diensten</li>
                        </ul>
                    </div>
                </div>
            </WillAnimate>

            {/* Case challenge */}
            <WillAnimate as="article" className="content content--coloured">
                <div className="container">
                    <div className="content__display">
                        <h2>Uitdaging</h2>
                    </div>

                    <div className="content__copy">
                        <p className="p-lead">
                            Als onderdeel van een taxatierapport dient de taxateur honderden vragen over de woning{' '}
                            handmatig te beantwoorden. De grootste uitdaging was om deze hoeveelheid vragen door middel{' '}
                            van algoritmes zoveel mogelijk <span className="highlight">geautomatiseerd</span> in te{' '}
                            vullen met de correcte data.
                        </p>
                        <p>
                            Om dit te realiseren moet de TaXapi webapplicatie koppelen met verschillende externe{' '}
                            diensten, bijvoorbeeld het Kadaster, om geautomatiseerd gegevens op te halen over de woning{' '}
                            voor een taxatie.
                        </p>
                        <p>
                            Daarnaast moet de berg met vragen over de te taxeren woning goed gesorteerd en op een{' '}
                            logische, <span className="highlight">tijdbesparende</span> manier getoond worden aan de{' '}
                            taxateur. Hiervoor is een strakke samenwerking van user interface en user experience
                            vereist.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="div" className="content">
                <div className="image">
                    <div className="image__content">
                        <Img
                            alt="Voorbeelden van schermen uit de TaXapi-webapplicatie"
                            fluid={data.taxapiScreens.childImageSharp.fluid}
                        />
                    </div>
                    <div className="image__caption">
                        <span className="ion-md-arrow-dropright">
                            De progressive webapplicatie kan als app gebruikt worden op mobiele apparaten maar ook{' '}
                            gewoon op de computer via een browser. Belangrijke onderdelen zijn zelfs helemaal offline{' '}
                            beschikbaar.
                        </span>
                    </div>
                </div>
            </WillAnimate>

            {/* Article idea and challenges description */}

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Creëren <br /> doe je <br /> samen
                        </h2>
                    </div>

                    <div className="content__copy">
                        <p className="p-lead">
                            De rol van <span className="highlight">product owner</span> wordt feilloos ingevuld door de{' '}
                            oud-taxateurs bij TaXapi. Zij bepalen continu de prioritering van de backlog, zowel tijdens{' '}
                            de initiële ontwikkeling voor de lancering van het product en ook nu wij samen het product{' '}
                            verder doorontwikkelen.
                        </p>
                        <p>
                            In de eerste ontwikkelperiode raceten we samen met TaXapi naar een{' '}
                            <span className="highlight">conceptvalidatie</span> om de interesse voor het product te{' '}
                            meten. Hiervoor ontwikkelden we een conceptversie van het eindproduct waarin de essentiële{' '}
                            functies uitgewerkt waren. Er is gedurende meerdere sessies met een groep van 20 taxateurs{' '}
                            <span className="highlight">uitgebreid getest</span>, voor de eerste publieke lancering.
                        </p>
                        <p>
                            Na een enthousiast ontvangst in de taxatiewereld itereerden we wekelijks door naar{' '}
                            verbeterde versies met <span className="highlight">tussenopleveringen</span>. Dit{' '}
                            stroom&shy;lijnde het proces om de software te testen in de echte wereld met taxateurs.{' '}
                        </p>
                        <p>
                            De opdrachtgevers gaven tijdens de vroege ontwikkelfases belangrijke inzichten vanuit het{' '}
                            gebruiksperspectief door hun jarenlange taxatie-ervaring. Daarnaast kwam het testen met de{' '}
                            groep echte gebruikers het platform ten goede gedurende het initiële ontwikkelproces. Onze{' '}
                            <span className="highlight">agile-aanpak</span> is ideaal voor ontwikkeltrajecten zoals die{' '}
                            van TaXapi.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content-group">
                        <div className="content-group__background content-group__background--right" />
                        <div className="content__image">
                            <div className="image image--detail">
                                <div className="image__content">
                                    <Img
                                        alt="Selecteer het aantal woonlagen in de interface van TaXapi."
                                        fluid={data.taxapiHuisSamenstellen.childImageSharp.fluid}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="content__copy">
                            <h2>Efficiënt werken met TaXapi</h2>
                            <p>
                                De user interface in de webapplicatie is zo ontworpen dat{' '}
                                <span className="highlight">efficiëntie</span> voor de taxateur voorop staat. Zo werkt
                                alles stapsgewijs, wordt altijd de voortgang opgeslagen en werkt alles zonder
                                internetverbinding.
                            </p>
                            <p>
                                In totaal wordt de voortgang van honderden vragen bijgehouden, zodat de taxateur op{' '}
                                <span className="highlight">elk moment</span> door kan gaan met het invullen van de
                                taxatie. Daarnaast geeft de vaste voortgangsbalk aan hoe de taxatie verloopt.
                            </p>
                            <p>
                                De mobiele aard van de webapplicatie zorgt ervoor dat de taxateur overal zijn werk kan{' '}
                                uitvoeren en daardoor meer tijd heeft om te taxeren en minder tijd op kantoor hoeft door
                                te brengen.
                            </p>
                        </div>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__image content__image--right">
                        <div className="image image--detail">
                            <div className="image__content">
                                <video
                                    width="100%"
                                    controls
                                    poster={withPrefix('/images/taxapi-video-placeholder.jpg')}>
                                    <source src={withPrefix('/video/taxapi.mp4')} type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>

                    <div className="content__copy">
                        <h2>Slimme koppelingen met externe diensten</h2>
                        <p>
                            Als taxateur ben je vaak verantwoordelijk voor het bij elkaar schrapen van informatie uit
                            verschillende bronnen over het taxatieobject. In de TaXapi-webapplicatie hebben we dit
                            proces zoveel mogelijk geautomatiseerd.
                        </p>
                        <p>
                            De applicatie heeft de mogelijkheid om uit minstens 11 verschillende externe bronnen de data
                            alvast op te halen. Daarnaast worden referentiepanden{' '}
                            <span className="highlight">automatisch</span> uitgekozen op basis van de kenmerken van de
                            huidige taxatie.
                        </p>
                        <p>
                            Dit gebeurt zonder dat de taxateur er iets voor hoeft te doen, wat de werklast voor de{' '}
                            taxateur verlaagt. Daar gaat een enorme{' '}
                            <span className="highlight">tijd&shy;besparing</span> mee gepaard.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content-group">
                        <div className="content-group__background content-group__background--right" />
                        <div className="content__image">
                            <div className="image image--detail">
                                <div className="image__content">
                                    <Img alt="taxapi taxatie" fluid={data.taxapiOverzicht.childImageSharp.fluid} />
                                </div>
                            </div>
                        </div>

                        <div className="content__copy">
                            <h2>Het taxatieproces verfijnd</h2>
                            <p>
                                De webapplicatie is zo ingericht dat het zowel taxateur als klant{' '}
                                <span className="highlight">in real time</span> op de hoogte houdt in welke fase de
                                taxatie zich bevindt.
                            </p>
                            <p>
                                Zo ziet de taxateur in real time wat nog moet gebeuren voor de taxatie van het
                                betreffende object. Daarnaast kunnen beide partijen inzichten uit het proces gebruiken
                                om voorbereidend werk alvast af te ronden.
                            </p>
                            <p>
                                Zodra het laatste onderdeel van de taxatie bereikt is ontvangt de klant een berichtje
                                met de mededeling dat de taxatie klaar is om{' '}
                                <span className="highlight">gedownload</span> te worden. Na betaling via iDeal wordt het
                                PDF-bestand vrijgegeven voor download. Taxateur en klant hebben zo een intensief proces{' '}
                                <span className="highlight">gemakkelijk</span> afgerond, direct in de webapplicatie.
                            </p>
                        </div>
                    </div>
                </div>
            </WillAnimate>

            {/* Article image and role  */}

            <WillAnimate as="article" className="content">
                <div className="image">
                    <div className="image__content">
                        <Img
                            alt="Klanten van TaXapi die de webapplicatie gebruiken."
                            fluid={data.taxapiCouple.childImageSharp.fluid}
                        />
                    </div>
                    <div className="image__caption">
                        <span className="ion-md-arrow-dropright">
                            Met TaXapi is het gehele taxatieproces inzichtelijk voor de klant. De klant kan zelf altijd{' '}
                            bij alle gegevens.
                        </span>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Ook een <br /> platform <br /> creëren?
                        </h2>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            TaXapi vernieuwt de Nederlandse taxatiemarkt met het nieuwe platform. Maar belangrijker{' '}
                            nog&mdash;wij bouwden samen een <span className="highlight">duurzame relatie</span> op. Heb{' '}
                            jij een interessant plan? Neem contact op met{' '}
                            <span className="inline-avatar">
                                <Img alt="Olav" fixed={data.olav.childImageSharp.fixed} Tag="span" />
                                <span>Olav</span>
                            </span>{' '}
                            voor een verkennend gesprek.
                        </p>
                    </div>
                    <div className="content__buttons content__buttons--50">
                        <Button url="/nl/contact/" name="Neem contact met ons op" title="Contactgegevens Enschede" />
                    </div>
                </div>
            </WillAnimate>
        </section>
    </Layout>
);

export const query = graphql`
    query {
        taxapi: file(relativePath: {eq: "portfolio/taxapi/taxapi-main.jpg"}) {
            ...fullscreenHero
        }

        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }

        taxapiScreens: file(relativePath: {eq: "portfolio/taxapi/taxapi-screens.jpg"}) {
            ...fluid1200Width
        }
        taxapiHuisSamenstellen: file(relativePath: {eq: "portfolio/taxapi/taxapi-huis-samenstellen.png"}) {
            ...fluid1200Width
        }
        taxapiOverzicht: file(relativePath: {eq: "portfolio/taxapi/taxapi-status.png"}) {
            ...fluid800Width
        }
        taxapiCouple: file(relativePath: {eq: "portfolio/taxapi/taxapi-couple.jpg"}) {
            ...fluid800Width
        }
    }
`;
